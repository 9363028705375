import { graphql } from 'relay-runtime';

const ProjectPage = graphql`
  query ProjectPageQuery($slug: String!, $url: String!, $username: String!) {
    portfolioProjectBySlug(slug: $slug) {
      id
      analyticsId
      slug
      title
      slateContent
      visitorCanEdit
      userProfile {
        id
        firstName
        lastName
        displayUsername
        generalInquiryCtaIsVisible
        productizedServices(excludeDrafts: true) {
          edges {
            node {
              id
            }
          }
        }
        portfolioProjects(type: PROJECT, excludeDrafts: true) {
          edges {
            node {
              id
            }
          }
        }
        externalLinks {
          edges {
            node {
              id
              label
              url
              socialMediaSite {
                nid
              }
            }
          }
        }
        ...Footer_userProfile
      }
      roles {
        edges {
          node {
            id
            name
          }
        }
      }
      tools {
        edges {
          node {
            id
            name
          }
        }
      }
      organizations {
        edges {
          node {
            id
            name
          }
        }
      }
      otherPortfolioProjectsByUser {
        edges {
          node {
            id
            slug
            title
            cover {
              ... on PortfolioProjectImageCover {
                __typename
                image {
                  id
                  animated
                  height
                  originalFormat
                  sizeBytes
                  uid
                  width
                }
              }
              ... on PortfolioProjectTextCover {
                __typename
                style
              }
            }
            roles {
              edges {
                node {
                  id
                  name
                }
              }
            }
            organizations {
              edges {
                node {
                  id
                  name
                }
              }
            }
          }
        }
      }
      ...Project_portfolioProject
    }

    userProfileByUsername(username: $username) {
      id
      ...UserProfile_userProfile
    }

    ...UrlMetaData_queryFragment
    ...IndependentPortfolioTemplates_queryFragment
  }
`;

export default ProjectPage;
